<template>
  <div class="container ui-center">
    <div class="warp">
      <img class="bg_w" src="@/assets/404_bgW.png" alt="">
      <h1>Oops, 您访问的系统服务已过期，<br />请等待新的服务系统上线。</h1>
    </div>
    <copyright/>
  </div>
</template>
<script>
import Copyright from '@/components/Copyright'
export default {
  name: 'Page404',
  data() {
    return {error: '页面未找到'}
  },
  components: {Copyright,},
  created() {}
}
</script>
<style lang="less" scoped>
  .warp{
    background: #4863bf;
    padding-top: 50px;
    position: relative;
    height: 490px;
    margin-bottom: 10px;
    .bg_w{
      width: 300px;
    }
    .bg{
      width: 100%;
    }
    h1{
      width: 100%;
      font-size: 16px;
      color: #fff;
      text-align: center;
      position: absolute;
      bottom: 25px;
    }
  }
</style>

